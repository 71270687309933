<template>
  <section
    v-if="products || products.length"
    class="recommended-products pt-3 mt-5 mb-5"
  >
    <div class="d-flex flex-wrap justify-content-between align-items-baseline">
      <h6 class="font-weight-bold text-color-444 ml-3 fontsize-large">
        <span style="font-weight: 900; font-size: 27px;">
          پیشنهادی
        </span>
        <span>
          همتا استور
        </span>
         
      </h6>
      <!-- <div class="line w-50"></div> -->
      <div class="recommended-products-category d-flex">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li
            v-for="(tab, index) in tabs"
            @click="setActiveProducts(tab)"
            :key="index"
            @mouseenter="loadTab(tab.name)"
            class="nav-item ml-1"
            role="presentation"
          >
            <a
              class="nav-link py-1 px-md-3 px-2 fontsize-large fontsize-md-17 bg-none"
              :class="{ active: activeTab == tab.name }"
              id="home-tab"
              aria-controls="home"
              aria-selected="true"
              >{{ tab.label }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="recommended-content tab-content mt-5" id="myTabContent">
      <div
        class="recommended-content-item tab-pane fade show active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div class="home-product-list">
          <Product
            v-for="(product, index) in products"
            :key="index"
            :product="product"
          ></Product>
        </div>

        <div class="text-center view-more">
          <router-link
            :to="activeRoute"
            class="recommended-products-more  view-more fontsize-small text-white bg-color-theme px-3 py-2 mt-3 d-inline-block"
            >مشاهده همه محصولات</router-link
          >
        </div>
      </div>
      <!-- <div
        class="recommended-content-item tab-pane fade"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div class="home-product-list">
          <Product
            v-for="(product, index) in suggestions"
            :key="'suggestions-' + index"
            :product="product"
          ></Product>
        </div>
        <div class="text-center">
          <router-link
            to="/products?sort=most_discounts"
            class="recommended-products-more fontsize-small text-white bg-color-theme px-3 py-2 mt-3 d-inline-block"
            >مشاهده همه محصولات</router-link
          >
        </div>
      </div> -->
      <!-- <div
          class="recommended-content-item tab-pane fade"
          id="discount"
          role="tabpanel"
          aria-labelledby="discount-tab"
      >
        <div class="home-product-list">
          <Product
              v-for="(product, index) in discounts"
              :key="'discounts-' + index"
              :product="product"
          ></Product>
        </div>
        <div class="text-center">
          <router-link
              to="/products?sort=most_discount"
              class="recommended-products-more fontsize-small text-white bg-color-theme px-3 py-2 mt-3 d-inline-block"
          >مشاهده همه محصولات</router-link
          >
        </div>
      </div> -->
      <!-- <div
        class="recommended-content-item tab-pane fade"
        id="mostsales"
        role="tabpanel"
        aria-labelledby="contact-tab"
      >
        <div class="home-product-list">
          <Product
            v-for="(product, index) in mostSales"
            :key="'mostSales' + index"
            :product="product"
          ></Product>
        </div>
        <div class="text-center">
          <router-link
            to="/products?sort=top_sales"
            class="recommended-products-more fontsize-small text-white bg-color-theme px-3 py-2 mt-3 d-inline-block"
            >مشاهده همه محصولات</router-link
          >
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>
import Product from "@/parts/Front/components/product/Product";
import { getImage } from "@/libraries/functions";

export default {
  name: "RecommendedProduct",
  components: {
    Product,
  },
  data() {
    return {
      readyProducts: null,
      activeRoute: "/products?sort=most_discount",
      tabs: [
        {
          name: "most-discounts",
          label: "شگفت‌انگیز ها",
        },
        {
          name: "suggestions",
          label: "پیشنهادی",
        },
        {
          name: "new-products",
          label: "محصولات جدید",
        },
      ],
      activeTab: "most-discounts",
      loadedTabs: ["mostDiscount"],
    };
  },
  inject: ["mediaQueries"],
  computed: {
    showCount() {
      if (this.mediaQueries.xxxLarge) {
        return 8;
      }
      if (this.mediaQueries.xxLarge) {
        return 8;
      }
      if (this.mediaQueries.xLarge) {
        return 8;
      }
      if (this.mediaQueries.medium) {
        return 6;
      }
      return 6;
    },
    // suggestions() {
    //   if (this.$store.getters["front/getHomeData"] == null) {
    //     return [];
    //   } else {
    //     return this.getEnough(
    //       this.$store.getters["front/getHomeData"].suggestions
    //     );
    //   }
    // },
    products() {
      // return Array(6).fill(null)
      return this.readyProducts;
    },
    // discounts() {
    //   if (this.$store.getters["front/getHomeData"] == null) {
    //     return [];
    //   } else {
    //     return this.getEnough(this.$store.getters["front/getHomeData"].mostDiscount)
    //   }
    // },
    // mostSales() {
    //   if (this.$store.getters["front/getHomeData"] == null) {
    //     return [];
    //   } else {
    //     return this.getEnough(
    //       this.$store.getters["front/getHomeData"].mostSales
    //     );
    //   }
    // },
    // new_products() {
    //   if (this.$store.getters["front/getHomeData"] == null) {
    //     return [];
    //   } else {
    //     return this.getEnough(
    //       this.$store.getters["front/getHomeData"].new_products
    //     );
    //   }
    // },
  },
  watch: {},
  methods: {
    // گرفتن مقدار لازم براساس تعدادی که باید نمایش بدیم بر اساس سایز صفحه
    getEnough(array) {
      return [...array].splice(0, Math.min(array.length, this.showCount));
    },
    getProductMainImageUrl(product) {
      return getImage(product.major_image, "lg");
    },
    loadTab(name) {
      if (this.loadedTabs.includes(name)) {
        return;
      }
      this.loadedTabs.push(name);
      // this[name].forEach((product) => {
      //   if (!product) return;
      //   let url = this.getProductMainImageUrl(product);
      //   let img = new Image();
      //   img.src = url;
      // });
    },
    setActiveProducts(tab) {
      if (tab.name == "new-products") {
        this.activeRoute = "/products?sort=top_sales";
      } else if (tab.name == "most-discounts") {
        this.activeRoute = "/products?sort=most_discount";
      } else {
        this.activeRoute = "/products";
      }
      this.activeTab = tab.name;
      this.setReadyProducts(this.activeTab);
    },
    async setReadyProducts(key) {
      this.activeTab = key;
      // localStorage.setItem("homeActiveTab", this.activeTab);
      this.readyProducts = null;
      let res = await this.$store.dispatch("front/getIndexPageContent", key);
      this.readyProducts = res;
    },
  },
  mounted() {
    this.setReadyProducts(this.activeTab);
  },
};
</script>

<style scoped>
.view-more{
  padding: 7px 16px !important;
  border-radius: 10px !important;
  margin-top: 1rem;
}
.nav-link {
  color: #000000;
  font-size: 16px !important;
  font-weight: 800;
}
.nav-link {
  border-radius: unset !important;
}
@media screen and(max-width: 500px) {
  .recommended-products-category a.fontsize-medium {
    font-size: 13px !important;
  }
}
.home-product-list {
  display: grid;
  grid-template-columns: repeat(4, 23%);
  gap: 1.5rem calc((100% - (4 * 23%)) / 3);
}
@media (max-width: 992px) {
  .home-product-list {
    display: grid;
    grid-template-columns: repeat(3, 31%);
    gap: 1.5rem calc((100% - (3 * 31%)) / 2);
  }
}
@media (max-width: 768px) {
  .home-product-list {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    gap: 1.5rem calc((100% - (2 * 48%)));
  }
}
@media (max-width: 360px) {
  .home-product-list {
    display: grid;
    grid-template-columns: repeat(1, 100%);
  }
}
</style>
