<template>
  <div>
    <div >
        <Header  />
        <router-view :key="$route.path"></router-view>
        <Footer />
        <FooterButtons v-if="$route.name != 'product.detail'" ></FooterButtons>
    </div>
    <div id="mainSpinner" >
      <div v-if="status" class="fulfilling-bouncing-circle-spinner">
        <div class="circle"></div>
        <div class="orbit"></div>
      </div>
    </div>
    <a id="mobileSupport" v-if="homeData" :href="`https://wa.me/${homeData?.settings?.social?.whatsapp}`">
    <img src="@/assets/images/whatsApp.png" alt="پشتیبانی واتس آپ  همتا استور">
      </a>

  </div>
</template>
<script>
import "bootstrap";
import Header from "@/parts/Front/components/shared/HeaderSecond";
import Footer from "@/parts/Front/components/shared/Footer";
  import FooterButtons from "@/parts/Front/components/FooterButtons";


//   در هر کامپوننت میتوانیم از این متغییر استفاده کنیم نحوه استفاده در کامپوننت زیر
// RespLoginModal
export default {
  name: "Home",
  components: {
    Header,
    Footer,
    FooterButtons
  },
  computed: {
    homeData(){
      return this.$store.getters["front/getHomeData"];

    },
    status() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
  },
  created() {
    // this.$store.dispatch("front/callGiftPackages"),
    this.$store.dispatch("front/getHomeDataFromServer", {
      $root: this.$root
    });
  },
};
</script>
<style scoped>
/* اسپینر استایل */
#mainSpinner{
    position: fixed;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999999999;
}
.fulfilling-bouncing-circle-spinner, .fulfilling-bouncing-circle-spinner * {
      box-sizing: border-box;
    }

    .fulfilling-bouncing-circle-spinner {
      height: 60px;
      width: 60px;
      position: relative;
      animation: fulfilling-bouncing-circle-spinner-animation infinite 4000ms ease;
    }

    .fulfilling-bouncing-circle-spinner .orbit {
      height: 60px;
      width: 60px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      border: calc(60px * 0.03) solid var(--color-theme3);
      animation: fulfilling-bouncing-circle-spinner-orbit-animation infinite 4000ms ease;
    }

    .fulfilling-bouncing-circle-spinner .circle {
      height: 60px;
      width: 60px;
      color: var(--color-theme3);
      display: block;
      border-radius: 50%;
      position: relative;
      border: calc(60px * 0.1) solid var(--color-theme3);
      animation: fulfilling-bouncing-circle-spinner-circle-animation infinite 4000ms ease;
      transform: rotate(0deg) scale(1);
    }

    @keyframes fulfilling-bouncing-circle-spinner-animation {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes fulfilling-bouncing-circle-spinner-orbit-animation {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1);
      }
      62.5% {
        transform: scale(0.8);
      }
      75% {
        transform: scale(1);
      }
      87.5% {
        transform: scale(0.8);
      }
      100% {
        transform: scale(1);
      }
    }

    @keyframes fulfilling-bouncing-circle-spinner-circle-animation {
      0% {
        transform: scale(1);
        border-color: transparent;
        border-top-color: inherit;
      }
      16.7% {
        border-color: transparent;
        border-top-color: initial;
        border-right-color: initial;
      }
      33.4% {
        border-color: transparent;
        border-top-color: inherit;
        border-right-color: inherit;
        border-bottom-color: inherit;
      }
      50% {
        border-color: inherit;
        transform: scale(1);
      }
      62.5% {
        border-color: inherit;
        transform: scale(1.4);
      }
      75% {
        border-color: inherit;
        transform: scale(1);
        opacity: 1;
      }
      87.5% {
        border-color: inherit;
        transform: scale(1.4);
      }
      100% {
        border-color: transparent;
        border-top-color: inherit;
        transform: scale(1);
      }
    }
</style>
