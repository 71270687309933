<template>
  <section

    v-if="amazingSuggest && amazingSuggest.length"
    id="amazingSuggest"
    class="py-4 pl-3 pr-3 pr-md-0 mt-5 w-100  d-flex justify-content-between"
  >
    <!-- <div
      id="amazingSuggestBanner"
      class="swiper-slide d-none d-md-flex flex-column align-items-center justify-content-center px-3"
    >
      <img
        width="92"
        height="77"
        src="@/assets/images/amazingSuggest.svg"
        alt="vip logo"
      />
      <div style="width: 140px; height: 115px">
        <img
          width="140"
          height="115"
          src="@/assets/images/amazingBox.png"
          alt="vip title"
        />
      </div>
      <router-link class="view-all text-light fontsize16 font-bold mt-1 border border-dark" to="/products"
        >مشاهده همه</router-link
      >
    </div> -->
    <div id="amazingSuggestproduct" class="swiper-container overflow-hidden w-100 position-relative ">
      <div class="swiper-wrapper">
        <div
          id="amazingSuggestBanner"
          class="swiper-slide d-flex d-md-none flex-column align-items-center justify-content-center px-3 swiper-slide"
          style="transform: translateY(114px);"
        >
          <img
            class="imgBanner"
            src="@/assets/images/amazingSuggest.svg"
            alt="vip logo"
          />
          <div >
            <img
              class="imgBanner"
                  id="bannerBox"
              style="transform: translateY(-7px);"
              src="@/assets/images/image-baner2.png"
              alt="vip title"
            />
          </div>
          <router-link
            class="view-all text-light fontsize16 font-bold  border border-white"
            to="/products?sort=most_discount"
            >مشاهده همه</router-link
          >
        </div>
        <div
        id="amazingSuggestBanner"
        class="swiper-slide d-none d-md-flex flex-column align-items-center justify-content-center px-3"
        style="transform: translateY(149px);"
      >
        <img
          class="imgBanner"
          src="@/assets/images/amazingSuggest.svg"
          alt="vip logo"
        />
        <div style="transform: translateY(-7px);">
          <img
          id="bannerBox"
            class="imgBanner"
            src="@/assets/images/amazingBox.png"
            alt="vip title"
          />
        </div>
        <router-link class="view-all text-light fontsize16 font-bold  border border-white" to="/products?sort=most_discount"
          >مشاهده همه</router-link
        >
      </div>
        <div
          class="swiper-slide border-radius20"
          v-for="(product, index) in amazingSuggest"
          :key="index"
        >
          <Product class="vipProduct" :product="product"></Product>
        </div>
        <div
          class="swiper-slide bg-dark more-slide d-flex justify-content-center align-items-center">
          
          <router-link class="text-light  fontsize16 text-black font-bold mt-1 d-flex flex-column justify-content-center align-items-center" to="/products?sort=most_discount"
          style="gap: 8px;"
          >
          <div class="more-slide-sum-svg d-flex justify-content-center align-items-center">  
            <svg width="35px" height="35px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 6a1 1 0 1 0-2 0v5H6a1 1 0 1 0 0 2h5v5a1 1 0 1 0 2 0v-5h5a1 1 0 1 0 0-2h-5V6Z" fill="#fff"/></svg>
        </div>
          <p>مشاهده همه</p>
          
          </router-link
        >
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Product from "@/parts/Front/components/product/Product";
import Swiper from "swiper/swiper-bundle.min";

export default {
  components: {
    Product,
  },
  mounted() {
    this.initRelatedProducts();
    this.loadData();
  },
  data(){
    return{
      amazingSuggest:null,
    }
  },
  computed: {
    // amazingSuggest() {
    //   if (this.$store.getters["front/getHomeData"] == null) {
    //     return [];
    //   } else {
    //     return this.$store.getters["front/getHomeData"].mostSales;
    //   }
    // },
  },
  watch: {
    amazingSuggest(newValue) {
      if (newValue) {
        this.initRelatedProducts(true);
      }
    },
  },
  methods: {
   async loadData(){
    this.amazingSuggest= await this.$store.dispatch("front/getIndexPageContent", 'suggestions');
   },
    initRelatedProducts(kill = false) {
      this.initSwiper("myamazingSuggest", kill, () => {
        new Swiper("#amazingSuggestproduct.swiper-container", {
          spaceBetween: 50,
          // centeredSlides: true,
          freeMode:true,
          loop: false,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".similar-products .swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            250: {
              slidesPerView: 1.3,
              spaceBetween: 5,
            },
            370: {
              slidesPerView: 1.5,
              spaceBetween: 5,

            },
            500: {
              slidesPerView: 2.5,
              spaceBetween: 10,
         

            },
            600: {
              slidesPerView: 2.8,
              spaceBetween: 12,
            },
            768: {
              slidesPerView: 3.2,
              spaceBetween: 30,
            },
            1000: {
              slidesPerView: 3.6,
              spaceBetween: 40,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1300: {
              slidesPerView: 4.5,
              spaceBetween: 40,
            },
            
            1600: {
              slidesPerView: 5,
              spaceBetween: 40,
            },
          },
        });
      });
    },
  },
};
</script>

<style>
section#amazingSuggest {
 /* background-color: var(--color-theme-main);*/
  background: linear-gradient(to right, #2766a5 , #1b4269);
  margin-top: 6rem !important;
}
#amazingSuggest {
 height: 450px;
}
#amazingSuggest .swiper-container {
 /* overflow-x: hidden;*/
  height: fit-content;
}
div#amazingSuggestproduct {
  bottom: 100px;
}
div#amazingSuggestproduct .swiper-slide {
  background: white;

}
#amazingSuggestBanner {
  background: transparent !important;
  width: 40%;
  margin: unset !important;
}

div#amazingSuggestproduct .vipProduct img {
  border-radius: 10px;
}
#amazingSuggestproduct img:not(#amazingSuggestBanner img) {
  height: 300px;
  width: 100%;
}
.view-all{
  transition: all .3s;
  padding: 7px 1rem;
  border-radius: 10px;
}
.more-slide{
  height: initial;
 /* background-color:#323232 !important;*/
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.more-slide a{
/*  color: #000 !important;*/
}
.more-slide a:hover{
 /* color: #000 !important;*/
}
.more-slide-sum-svg{
  width: 40px;
  height: 40px;
  border: 1px solid #fff;
  border-radius: 50%;
}
.view-all:hover{
  background-color:#000;
  color: #fff !important;
}

@media (min-width: 799px) and (max-width : 1300px) {
  section#amazingSuggest {
  background-color: var(--color-theme-main);
  margin-top: 10rem !important;
}
}

@media (max-width: 1200px) {
  #amazingSuggestBanner{
    transform: translateY(88px) !important ;
    width: 50% !;
  }
  #amazingSuggestproduct img:not(#amazingSuggestBanner img) {
    height: 250px !important;
  }
  #amazingSuggest {
    height: 430px !important;
  }
  #amazingSuggestproduct{
    bottom: 82px !important;
  }

}
@media (max-width: 768px) {
  #amazingSuggestBanner{
    transform: translateY(111px) !important ;
   
  }

  .imgBanner{
    width: 163px;
    height: 118px;
  }

  #bannerBox{
    transform: translateY(15px) !important ;
   
  }
  .view-all{
    transform: translateY(35px) !important ;
  }

  #amazingSuggestproduct{
    bottom: 100px !important;
  }
}
@media (max-width: 600px) {
  section#amazingSuggest {
    margin-top: 8rem !important;
  }
  .imgBanner{
    width: 125px;
    height: 107px;
  }

}
@media (max-width: 500px) {
  #amazingSuggestBanner{
    transform: translateY(90px) !important ;
   
  }
  .imgBanner{
    width: 125px;
    height: 107px;
  }
  #amazingSuggest{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

}

</style>
